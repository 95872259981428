import { domain } from 'config';
import http from 'utils/axiosService';
import { useState, useEffect } from 'react';

const vehicleTypesEndpoint = `${domain}/api/vehicleTypes`;

export const useVehicleTypeHook = () => {
  const [vehicleTypes, setvehicleTypes] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await http.get(vehicleTypesEndpoint);
      setvehicleTypes(response.data);
    };
    fetchData();
  }, []);
  return vehicleTypes;
};
