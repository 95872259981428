import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import { Navigate } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import React from 'react';
// render - dashboard
const Reports = Loadable(lazy(() => import('pages/reports')));

// render - sample page
const Vehicles = Loadable(lazy(() => import('pages/Vehicles')));
const Vehicle = Loadable(lazy(() => import('pages/Vehicle')));
const Configuration = Loadable(lazy(() => import('pages/Configuration')));

const Services = Loadable(lazy(() => import('pages/Services')));
const Service = Loadable(lazy(() => import('pages/Service')));
const ServiceItem = Loadable(lazy(() => import('pages/ServiceItem')));

const Transportations = Loadable(lazy(() => import('pages/Transportations')));
const Parts = Loadable(lazy(() => import('pages/Parts')));
const Part = Loadable(lazy(() => import('pages/Part')));

const Transportation = Loadable(lazy(() => import('pages/Transportation')));
const ProductionDetails = Loadable(lazy(() => import('pages/ProductionDetails')));

const SalesOrders = Loadable(lazy(() => import('pages/SalesOrders')));
const SalesOrder = Loadable(lazy(() => import('pages/SalesOrder')));
const WorkOrders = Loadable(lazy(() => import('pages/WorkOrders')));
const WorkOrder = Loadable(lazy(() => import('pages/WorkOrder')));
const SalesOrderItemDetailTemplate = Loadable(lazy(() => import('pages/SalesOrderItemDetailTemplate')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: '/',
      element: <Vehicles />
    },
    {
      path: '/:vehicleId/*',
      element: <Vehicle />
    },
    {
      path: 'vehicles',
      element: <Vehicles />
    },
    {
      path: 'vehicles/:vehicleId/*',
      element: <Vehicle />
    },
    {
      path: 'salesOrders',
      element: <SalesOrders />
    },
    {
      path: 'salesOrders/:id',
      element: <SalesOrder />
    },
    {
      path: 'workOrders',
      element: <WorkOrders />
    },
    {
      path: 'workOrders/:id',
      element: <WorkOrder />
    },
    {
      path: 'services',
      element: <Services />
    },
    {
      path: 'services/:id',
      element: <Service />
    },
    {
      path: 'serviceitems/:id',
      element: <ServiceItem />
    },
    {
      path: 'transportations',
      element: <Transportations />,
      requireAuth: true
    },
    {
      path: 'parts',
      element: <Parts />,
      requireAuth: true
    },
    {
      path: 'parts/:id',
      element: <Part />
    },
    {
      path: 'transportations/:id',
      element: <Transportation />
    },
    {
      path: 'configuration',
      element: <Configuration />
    },
    {
      path: 'reports',
      element: <Reports />
    },
    {
      path: 'productionDetails/:id',
      element: <ProductionDetails />
    },

    {
      path: 'salesOrderItemDetailTemplate',
      element: <SalesOrderItemDetailTemplate />
    }
  ]
};

export default MainRoutes;
