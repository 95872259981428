import { initializeApp } from 'firebase/app';
import {
  getAuth,
  onIdTokenChanged,
  onAuthStateChanged,
  signOut,
  signInWithRedirect,
  GoogleAuthProvider,
  getRedirectResult,
  browserLocalPersistence,
  signInWithPopup
} from 'firebase/auth';
import { firebaseConfig } from 'config';
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const provider = new GoogleAuthProvider();
auth.setPersistence(browserLocalPersistence);
export const signInUser = async () => {
  return await signInWithPopup(auth, provider);
};

export const userStateListener = (callback) => {
  return onAuthStateChanged(auth, callback);
};

export const getToken = async () => await auth.currentUser?.getIdToken();

export const SignOutUser = async () => await signOut(auth);
