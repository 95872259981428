import { domain } from 'config';
import http from 'utils/axiosService';
import { useState, useEffect } from 'react';

const vehicleEndpoint = `${domain}/api/vehicles`;

export const useVehicleHook = (vehicleId) => {
  const [vehicle, setvehicle] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (vehicleId) {
          const response = await http.get(vehicleEndpoint + '/' + vehicleId);
          setvehicle(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [vehicleId]);
  return vehicle;
};

export const useVehiclesHook = () => {
  const [vehicles, setvehicles] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await http.get(vehicleEndpoint);
      setvehicles(response.data);
    };
    fetchData();
  }, []);
  return vehicles;
};

export const useVehicleVINSelectListItemsHook = () => {
  const [VINs, setVINs] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await http.get(vehicleEndpoint + '/vinSelectListItems');
      setVINs(response.data);
    };
    fetchData();
  }, []);
  return VINs;
};

export const useVehicleVINsHook = () => {
  const [VINs, setVINs] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await http.get(vehicleEndpoint + '/vins');
      setVINs(response.data);
    };
    fetchData();
  }, []);
  return VINs;
};

export const useVehiclesByCustomerGuidHook = (customerGuid) => {
  const [vehicles, setvehicles] = useState([]);
  if (!customerGuid) {
    return [];
  }
  useEffect(() => {
    const fetchData = async () => {
      const response = await http.get(`${domain}/api/public/vehicles`, { params: { customerGuid } });
      setvehicles(response.data);
    };
    fetchData();
  }, []);
  return vehicles;
};
