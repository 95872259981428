import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { domain } from 'config';
import { getToken } from 'firebaseUtil';

// const instance = Axios.create();
// const axios = setupCache(instance);

// Add a request interceptor
axios.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    const accessToken = await getToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    config.baseURL = domain;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch
};
