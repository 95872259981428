import {
  Box,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Autocomplete,
  CircularProgress,
  TextField,
  darken,
  lighten,
  styled
} from '@mui/material';

// assets
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import { useVehicleVINsHook } from 'hooks/useVehicleHook';
import { useCustomerHook } from 'hooks/useCustomerHook';
import { useVehicleTypeHook } from 'hooks/useVehicleTypeHook';

const SearchMultiTypes = () => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const vins = useVehicleVINsHook();
  const customers = useCustomerHook();
  const vehicleTypes = useVehicleTypeHook();
  const navigate = useNavigate();
  const dataWithGroup = [...new Set(customers)]
    .filter((x) => !!x.name)
    .map((item) => ({ group: 'Customer', label: item.name, id: item.id }))
    .concat([...new Set(vehicleTypes)].filter((x) => !!x.name).map((item) => ({ group: 'Type', label: item.name, id: item.id })))
    .concat([...new Set(vins)].filter((x) => !!x.name).map((item) => ({ group: 'VIN', label: item.name, id: item.id })));
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (active && dataWithGroup) {
      setOptions([...dataWithGroup]);
    }

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.primary.light, 0.85) : darken(theme.palette.primary.main, 0.8)
  }));
  const GroupItems = styled('ul')({
    padding: 0
  });
  return (
    <Box sx={{ width: '100%', ml: { xs: 0, md: 1, alignContent: 'center' } }}>
      <FormControl sx={{ width: '80%', maxWidth: '400' }}>
        <Autocomplete
          id="vin-search"
          autoHighlight
          sx={{ width: '100%' }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={(e) => {
            setOpen(false);
          }}
          onChange={(event, value) => {
            if (value?.group === 'Customer' || value?.group === 'Type') {
              navigate(`/?query=${value.group}%3D${value.id}`);
            } else if (value?.group === 'VIN') {
              navigate('/' + value.id);
            } else {
              navigate('/');
            }
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.label}
          options={options}
          loading={loading}
          groupBy={(option) => option.group}
          renderInput={(params) => (
            <TextField
              {...params}
              id="header-search"
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{loading ? <CircularProgress color="inherit" size={20} /> : null}</>,
                startAdornment: (
                  <InputAdornment position="start" sx={{ mr: -0.5 }}>
                    <SearchOutlined />
                  </InputAdornment>
                )
              }}
              placeholder="Search By VIN, Customer, or Vehicle Type"
            />
          )}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
        />
      </FormControl>
    </Box>
  );
};

export default SearchMultiTypes;
