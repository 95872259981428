// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: '/',
  fontFamily: "'Public Sans', sans-serif",
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr'
};

export default config;
export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

export const domain = process.env.NODE_ENV === 'production' ? location.origin : 'https://localhost:5001';
// Your web app's Firebase configuration

export const firebaseConfig = {
  apiKey: 'AIzaSyBsQdHzHVffjgPSrumCx_XG5gC9QLoYmoM',
  authDomain: 'carman-173f2.firebaseapp.com',
  projectId: 'carman-173f2',
  storageBucket: 'carman-173f2.appspot.com',
  messagingSenderId: '988994199171',
  appId: '1:988994199171:web:5c4c7bd0c715279cdd45b6'
};
