import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import React from 'react';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const ServiceRequest = Loadable(lazy(() => import('pages/public/ServiceRequest')));
const TruckBody = Loadable(lazy(() => import('pages/public/TruckBody')));
const Order = Loadable(lazy(() => import('pages/public/Order')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'serviceRequest',
      element: <ServiceRequest />
    },
    {
      path: 'truckBody/:id',
      element: <TruckBody />
    },
    {
      path: 'truckBody',
      element: <TruckBody />
    },
    {
      path: 'order',
      element: <Order />
    }
  ]
};

export default LoginRoutes;
