import React, { useContext, useEffect } from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';

const ProtectedRoute = (props) => {
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();

  if (currentUser.isLoading) {
    return <React.Fragment>Loading......</React.Fragment>;
  }
  if (currentUser?.data?.email) {
    if (currentUser?.data?.email?.includes('gptruckbody.com')) {
      // custom redirect for lions truck
      return <Navigate to="/truckBody" replace />;
    }
    return <React.Fragment>{props.children}</React.Fragment>;
  }
  return <Navigate to="/login" state={{ from: location }} replace />;
};
export default ProtectedRoute;
